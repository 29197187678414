import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({
  margin: {
    paddingRight: '5%',
    paddingLeft: '5%',
    paddingTop: '1%'
  },
  text: {
    paddingTop: '1.5%',
    paddingBottom: '1%',
    '@media (max-width:570px)': {
        textAlign: 'justify'
    },
  },
  fourBoxes: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3%',
    '@media (max-width:550px)': {
        flexDirection: 'column-reverse'
    },
  },
  colorTextSecondary: {
    color: '#fff',
    fontWeight: 700,
    fontSize: '1.5rem',
    '@media (max-width:550px)': {
        fontSize: '1.2rem'
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  iconContainer: {
    backgroundColor: '#C45824 !important',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    '@media (max-width:550px)': {
        margin: '3%'
    }
  },
  balanceIconSmall: {
    display: 'none',
    '@media (max-width: 549px)': {
        display: 'inline-block'
    }
  },
  balanceIconBig: {
    '@media (max-width:550px)': {
      display: 'none'
    },
  },
  euroIcon: {
    color: '#ffffff',
    fontSize: '6rem !important',
    '@media (max-width:550px)': {
    fontSize: '4rem !important',
    },
  },
  image: {
    height: 200,
    width: 284,
    '@media (max-width:550px)': {
        display: 'none'
    },
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    paddingLeft: 10,
    marginBottom: 30,
    borderRadius: 50,
    height: 98,
    backgroundColor: '#C45824',
    "&:hover": {
      backgroundColor: '#CF794F',
    },
    width: '95%',
    [theme.breakpoints.up("md")]: {
      width: 571,
    }
  },
  titleButton: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 'clamp(1.5rem, 3vw, 25px)'
  }
}));

