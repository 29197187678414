import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { addProtocol } from '../utils/format';
import EuroRoundedIcon from '@material-ui/icons/EuroRounded';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

import useStyles from '../pagesStyles/jurdiqueStyles';

import Layout from '../components/layout';
import Hero from '../components/Hero/index';
import Icon from '../components/Icons/Icon/index';
import Subtitle from '../components/Subtitle/index'

const JuridiquePage = ({ data }) => {

  const classes = useStyles();

  const md = data.markdownRemark.frontmatter

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{md.SEOtitle}</title>
        <meta name="description" content={md.SEOdescription} />
      </Helmet>
      
      <Hero
        image={md.coverImage.childImageSharp.gatsbyImageData}
        title={md.title}
      />
      <Box className={classes.margin}>

        <Subtitle text={md.paragraph} />

        {/* button brings to webapp legal contact form */}
        <Grid item container direction="row" alignContent="center" justify="center">
        <Button
          variant="contained"
          classes={{ root: classes.button }}
          href={`${addProtocol(process.env.GATSBY_ADHERENT_URL, "https")}/contact`}
          target="_blank"
        >
          <Typography variant="h1" className={classes.titleButton}>Contactez l'aide juridique</Typography>
        </Button>
      </Grid>

        <Box className={classes.fourBoxes}>
          <Box className={classes.rowContainer}>
            <div className={classes.image}>
              <GatsbyImage image={md.justicePic.childImageSharp.gatsbyImageData} alt="justice" />
            </div>
            <Link to='/juridique/cadre-juridique' className={classes.link}>
              <div className={classes.iconContainer}>
                <div className={classes.balanceIconSmall}>
                  <Icon icon={faBalanceScale} variant='white' size='4x' />
                </div>
                <div className={classes.balanceIconBig}>
                  <Icon icon={faBalanceScale} variant='white' size='6x' />
                </div>
                <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                  {md.subtitle1}
                </Typography>
              </div>
            </Link>
          </Box>

          <Box className={classes.rowContainer}>
            <Link to='/juridique/contentieux' className={classes.link}>
              <div className={classes.iconContainer}>
                <EuroRoundedIcon fontSize='large' className={classes.euroIcon} />

                <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                  {md.subtitle2}
                </Typography>
              </div>
            </Link>
            <div className={classes.image}>
              <GatsbyImage image={md.contentieuxPic.childImageSharp.gatsbyImageData} alt="files" />
            </div>
          </Box>
        </Box>

      </Box>
    </Layout>
  )
}

export default JuridiquePage

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/juridique.md/"}) {
        frontmatter {
            SEOtitle
            SEOdescription
            coverImage {
                childImageSharp {
                    gatsbyImageData
                    }
                }
            title
            paragraph
            subtitle1
            subtitle2
            justicePic {
                childImageSharp {
                    gatsbyImageData(
                        height: 200
                        width: 284
                        placeholder: BLURRED
                    )
                    }
                }
            contentieuxPic {
                childImageSharp {
                    gatsbyImageData (
                        height: 200
                        width: 284
                        placeholder: BLURRED
                    )
                }
            }
        }
    }
}
`
